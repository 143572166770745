import React, { useState } from "react"

import {
  Crown,
  RibbonBlack,
  RibbonBlue,
  RibbonStroke,
} from "../../../components/icons"
import * as styles from "./index.module.scss"
import Table from "../../../components/table"

const planTypeToTheme = {
  "Essentials Plan": "beltBasic",
  "Professional Plan": "beltBlue",
  "Premium Plan": "beltBlack",
  "Consultation Included": "beltEnterprise",
}

const planTypeToIcon = {
  "Essentials Plan": RibbonStroke,
  "Professional Plan": RibbonBlue,
  "Premium Plan": RibbonBlack,
  "Consultation Included": Crown,
}

const Tables = ({ data }) => {
  const [paymentStatus, setPaymentStatus] = useState("anually")

  return (
    <div>
      <div className={`${styles.paymentSwitcher}`}>
        <div className="px-4 xl:px-0 mx-auto max-w-6xl py-8 flex flex-col lg:flex-row">
          <h3 className="text-white flex-1 text-center lg:text-left mb-6 lg:mb-0">
            Choose your payment period
          </h3>
          <div className="flex items-center justify-center lg:justify-start">
            <button
              className={`${styles.switchButton} ${
                paymentStatus === "anually" && styles.switchButtonActive
              } bggh`}
              onClick={() => setPaymentStatus("anually")}
            >
              <span className="flex justify-center items-center z-10">
                Annually
              </span>
            </button>
            <div className="pr-5" />
            <button
              className={`${styles.switchButton} ${
                paymentStatus === "monthly" && styles.switchButtonActive
              } bggh`}
              onClick={() => setPaymentStatus("monthly")}
            >
              <span className="flex justify-center items-center z-10">
                Monthly
              </span>
            </button>
          </div>
        </div>
      </div>
      <div className="px-4 xl:px-0 mt-16 max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-x-4 gap-y-12">
        {data.map(
          ({
            planType,
            pricePerAnnum,
            pricePerMonth,
            title,
            features,
            isPopular,
          }) => (
            <Table
              title={title}
              subTitle={planType}
              costMonthly={pricePerMonth}
              costAnually={pricePerAnnum}
              features={features}
              status={paymentStatus}
              theme={planTypeToTheme[planType]}
              icon={planTypeToIcon[planType]}
              popular={isPopular}
            />
          )
        )}
      </div>
      <div className="px-4 xl:px-0 max-w-6xl mx-auto pt-6 lg:pb-2">
        <span>
          *Additional users can be added to any plan for $19/mo per user.
        </span>
      </div>
    </div>
  )
}

export default Tables
