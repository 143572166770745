import React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import LinkButton from "../../../components/linkButton"
import { Check } from "../../../components/icons"
import * as styles from "./index.module.scss"

const Plans = ({ data }) => {
  const image = getImage(data.featuresImage)
  return (
    <div className={`${styles.planContainer} mt-8 py-8 px-4 xl:px-0`}>
      <div className="mx-auto max-w-6xl flex flex-col lg:flex-row items-center gap-x-8">
        <div className="lg:w-3/12 xl:w-2/12 flex-col lg:flex-row flex justify-center items-center">
          <h3 className="block lg:hidden mb-6 text-center">
            {data.featuresTitle}
          </h3>
          <GatsbyImage
            image={image}
            alt={data.featuresImage.title}
            loading="lazy"
            className="w-2/5 lg:w-full"
          />
        </div>
        <div className="flex-1">
          <h3 className="hidden lg:block mb-4">{data.featuresTitle}</h3>
          <div className="flex flex-col">
            {data.featuresList.map(item => {
              return (
                <div className="flex pb-3">
                  <div className={`${styles.checkContainer}`}>
                    <Check color="#0D52FF" size={16} />
                  </div>
                  <span className="flex-1 pl-4">{item}</span>
                </div>
              )
            })}
          </div>
          <LinkButton to="/quotes" className="mt-4 lg:ml-7 mx-auto">
            View All Features
          </LinkButton>
        </div>
      </div>
    </div>
  )
}

export default Plans
