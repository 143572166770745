import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Banner from "../components/banner"
import PricingPlans from "../containers/Pricing/PricingPlans"
import Contact from "../containers/Pricing/Contact"
import Features from "../containers/Pricing/Features"
import FAQs from "../components/faq"

const Pricing = ({ data }) => {
  const {
    ogTitle,
    ogDescription,
    bannerTitle,
    bannerSubtitle,
    bannerImage,
    pricingPlans,
    contactTitle,
    contactSubtitle,
    featuresTitle,
    featuresImage,
    featuresList,
    faQsTitle,
    faqsList,
  } = data.pricing

  const contactData = { contactTitle, contactSubtitle }
  const featureData = { featuresTitle, featuresImage, featuresList }
  const faqData = { faQsTitle, faqsList }

  return (
    <Layout>
      <Seo title={ogTitle || "Pricing"} description={ogDescription} />
      <Banner title={bannerTitle} slogan={bannerSubtitle} image={bannerImage} />
      <PricingPlans data={pricingPlans} />
      <Contact data={contactData} />
      <Features data={featureData} />
      <FAQs data={faqData} whiteBG />
    </Layout>
  )
}

export const query = graphql`
  query PricingQuery {
    pricing: contentfulPricingPage {
      ogTitle
      ogDescription
      bannerTitle
      bannerSubtitle
      bannerImage {
        title
        gatsbyImageData(width: 400, quality: 100, placeholder: BLURRED)
      }
      pricingPlans {
        pricePerAnnum
        pricePerMonth
        title
        planType
        isPopular
        features {
          title
          type
        }
      }

      contactTitle
      contactSubtitle
      featuresTitle
      featuresImage {
        title
        gatsbyImageData(width: 245, quality: 100, placeholder: BLURRED)
      }
      featuresList
      faQsTitle
      faqsList {
        answer
        question
      }
    }
  }
`

export default Pricing
