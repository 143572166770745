import React from "react"
import { Link } from "gatsby"

import * as styles from "./index.module.scss"

const Contact = ({ data }) => {
  return (
    <div
      className={`${styles.contactContainer} mt-12 px-4 xl:px-0 py-7 mx-auto max-w-7xl`}
    >
      <div className=" mx-auto max-w-6xl flex flex-col lg:flex-row items-center">
        <div className="flex-1">
          <h3 className="text-white text-center lg:text-left mb-4 lg:mb-0">
            {data.contactTitle}
          </h3>
          <p className="text-white text-center lg:text-left py-4">
            {data.contactSubtitle}
          </p>
        </div>
        <div>
          <Link to="/contact" className={`${styles.contactButton} bggh`}>
            <div className="z-10">Contact Us</div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Contact
