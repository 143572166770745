import React from "react"

import { Check, CheckInvert } from "../icons"
import TrialButton from "../trialButton"
import * as styles from "./index.module.scss"

const Table = ({
  icon: Icon,
  title,
  subTitle,
  theme,
  costMonthly,
  costAnually,
  status,
  features,
  popular,
}) => {
  return (
    <div className="flex flex-col">
      <div className={`${styles.belt} ${styles[theme]}`}>
        <div className={`${styles.badge}`}>
          <Icon />
        </div>
      </div>
      <div className={`${styles.beltContent} flex-1`}>
        {popular && (
          <div className={`${styles.beltPopular}`}>
            <span className="text-white">Most Popular</span>
          </div>
        )}
        <h5>{title}</h5>
        <p>{subTitle}</p>
        <span className={`${styles.beltPricing}`}>
          <h5>{status === "anually" ? costAnually : costMonthly} USD</h5>
          <h4 className=" pt-4">/mo</h4>
        </span>
        <p>Billed {status}</p>
        <div className="py-8 px-6">
          <TrialButton className="bggh" flexible>
            <div className="z-10">Start Free Trial</div>
          </TrialButton>
        </div>
        <ul className={`${styles.beltFeatures} px-6`}>
          {features.map(feat => {
            const type = feat.type.toLowerCase().replace(" ", "")
            return (
              <li className={`${styles.featureItem}`}>
                {type === "whitebelt" ? (
                  <CheckInvert color="#fff" size={18} className="w-5" />
                ) : (
                  <Check
                    color="#fff"
                    size={18}
                    className={`${styles.featureCheck} ${styles[type]} w-5`}
                  />
                )}
                <span className="text-left pl-3 w-full">{feat.title}</span>
              </li>
            )
          })}
        </ul>
        <div className="py-8 px-6">
          <TrialButton className="bggh" flexible>
            <div className="z-10">Start Free Trial</div>
          </TrialButton>
        </div>
      </div>
    </div>
  )
}

export default Table
