// extracted by mini-css-extract-plugin
export var badge = "index-module--badge--pvhC4";
export var belt = "index-module--belt--dBVIv";
export var beltBlack = "index-module--beltBlack--uKJzV";
export var beltBlue = "index-module--beltBlue--RnhzQ";
export var beltContent = "index-module--beltContent--R0VDX";
export var beltEnterprise = "index-module--beltEnterprise--JFlNE";
export var beltFeatures = "index-module--beltFeatures--OG4IU";
export var beltPopular = "index-module--beltPopular--qz0qu";
export var beltPricing = "index-module--beltPricing--pYlne";
export var blackbelt = "index-module--blackbelt--SleY1";
export var bluebelt = "index-module--bluebelt--6V8cU";
export var enterprise = "index-module--enterprise--f5KX9";
export var featureCheck = "index-module--featureCheck--2HyYx";
export var featureItem = "index-module--featureItem--mcYuH";